import React from "react";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import MortgageCalculator from "../../components/MortgageCalculator";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const WasKostetEinHausInSalzburg = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Was kostet ein Haus in Salzburg?" showCalc={false} />
            <Article>
                <p>
                    Haus, der Traum! Auf der Suche nach dem passenden Eigenheim ist vor allem eines wichtig: sich
                    möglichst früh einen Überblick über das Angebot zu verschaffen und ein Gespür für regionale
                    Unterschiede auf dem Markt zu entwickeln. In den Artikeln dieser Serie widmen wir uns daher den
                    einzelnen Bundesländern und geben dir einen ersten Überblick über das dortige Angebot. Diesmal an
                    der Reihe: Salzburg.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Immobilienpreise in Österreich – die aktuelle Tendenzen</h2>
                <p>
                    Die Kreditzinsen sind in Österreich aktuell sehr günstig, weshalb besonders viele Menschen, derzeit
                    in Immobilien investieren. Ein teilweise rapider Anstieg der Preise auf dem Wohnungsmarkt ist die
                    Folge. Seit dem vierten Quartal 2020 steigen die Preise im zweistelligen Prozentbereich. Im ersten
                    Quartal 2021 wurde Wohnraum um 12,3 % teurer, im zweiten war ein Preisanstieg von 11,7 % zu
                    beobachten. Während sich der Anstieg der Kaufpreise für Einfamilienhäuser außerhalb Wiens 2021 von
                    12,9 % im ersten auf 11,3 % im zweiten Quartal verringerte, hielt der Aufwärtstrend für gebrauchte
                    Eigentumswohnungen an: Im zweiten Quartal bezahlte man durchschnittlich 14,3 % mehr. Besonders
                    rasant wachsen derzeit die Immobilienpreise außerhalb Wiens. In der Landeshauptstadt stiegen sie im
                    ersten Quartal 2021 um 10,9, im zweiten um 10,7 %. Dagegen waren in den anderen Bundesländern in
                    diesem Zeitraum Wachstumsraten von 14 bzw. 12,8 % zu beobachten. Besonders für den
                    Neubauwohnungsmarkt gilt dieser Trend: Stiegen die Preise für neue Immobilien in Wien 2021 um 10,4
                    im ersten und von 10, 1 % zweiten Quartal, so kam es in den übrigen Bundesländern zu einem Anstieg
                    von 16,7 und 12,9 %.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Der Markt in Salzburg</h2>
                <p>
                    Sowohl der Mietzins als auch die Preise für Einfamilienhäusern steigen in Salzburg anhaltend. Das
                    Wachstum gestaltet sich in den meisten Fällen jedoch eher moderat. In Salzburg Stadt sind es
                    insbesondere Baugrundstücke, die eine rapide Preisentwicklung zeigen: Im Gegensatz zum Umland, wo du
                    mit etwa 330,39 € pro Quadratmeter rechnen musst, zahlst du hier satte 820, 42 € für einen
                    Quadratmeter Baugrund. Etwas geringer fällt der Unterschied bei Eigentumswohnungen aus. Ein
                    Quadratmeterpreis von 4 216,09 € in Salzburg Stadt steht in diesem Fall einem Preis von 3 672,50
                    gegenüber.
                </p>
                <hr />

                <h2>Das kostet ein Haus in Salzburg</h2>
                <p>
                    Erheblich teurer sind Häuser in Salzburg. Besonders hoch ist der Quadratmeterpreis in der der
                    Innenstadt: Er beträgt 2.783,48 €. Immer noch deutlich über dem Preis der Wiener Außenbezirke liegt
                    Zell am See mit 2.524,78 € und auch in Hallein kann man sich mit einem Preis von 2.508,42 € pro
                    Quadratmeter locker mit der Bundeshauptstadt messen. Das gilt auch für St. Johann im Pongau, wo dich
                    ein Betrag von 2.402 € pro Quadratmeter erwartet. Der Quadratmeterpreis in Höhe von 1.812,33 € in
                    Tamsweg wirkt da fast schon wie ein Schnäppchen.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
            </Article>
            <MortgageCalculator noTopMargin defaultTerm={35} defaultMortgage={50000} />

            <BreadcrumbList page={"wasKostetEinHausInSalzburg"}></BreadcrumbList>
            <ArticleStructuredData page={"wasKostetEinHausInSalzburg"} heading={"Was kostet ein Haus in Salzburg?"} />
        </Layout>
    );
};

export default WasKostetEinHausInSalzburg;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.was-kostet-ein-haus-in-salzburg", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
